export default [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/erro'
  },
  {
    path: '/',
    name: 'login',
    component: () => import('../components/admin/login/Login.vue'),
    meta: {
      logged: false,
      title: 'Login Admin',
      layout: 'default-layout'
    }
  },
  {
    path: '/painel',
    name: 'painel',
    component: () => import('../components/admin/painel/Painel.vue'),
    meta: {
      logged: false,
      title: 'Painel Admin',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/erro',
    name: 'erro',
    component: () => import('../components/admin/erro/Erro.vue'),
    meta: {
      logged: false,
      title: 'Essa página não existe...',
      layout: 'default-layout'
    }
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('../components/admin/perfil/Perfil.vue'),
    meta: {
      logged: true,
      title: 'Perfil | Admin Oficina das Rifas',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/sorteios',
    name: 'sorteios',
    component: () => import('../components/admin/sorteios/Sorteios.vue'),
    meta: {
      logged: true,
      title: 'Sorteios | Admin Oficina das Rifas',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/sorteio/dashboard',
    name: 'sorteioDashboard',
    component: () => import('../components/admin/sorteio/dashboard/Dashboard.vue'),
    meta: {
      logged: true,
      title: 'Dashboard Sorteio | Admin Oficina das Rifas',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/sorteio/compradores',
    name: 'sorteioCompradores',
    component: () => import('../components/admin/sorteio/compradores/Compradores.vue'),
    meta: {
      logged: true,
      title: 'Compradores Sorteio | Admin Oficina das Rifas',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/sorteio/rifas',
    name: 'sorteioRifas',
    component: () => import('../components/admin/sorteio/rifas/Rifas.vue'),
    meta: {
      logged: true,
      title: 'Rifas Sorteio | Admin Oficina das Rifas',
      layout: 'navbar-layout'
    }
  }
]
